import * as React from "react";

import * as styles from "./index.module.scss";
import Svg from "../../images/faq-banner.inline.svg";
import termsBanner from "../../images/affilieate_start@3x.png";
import termsBanner768 from "../../images/affilieate_start@2x.png";
import termsBanner320 from "../../images/affilieate_start.png";

import SectionLayout from "../../components/SectionLayout";
import BackgroundImage from "../../components/BackgroundImage";
import JoinButton from "../../components/JoinButton";

const AffiliateStartSection = () => {
    return (
        <SectionLayout className={styles.sectionWrapper}>
            <BackgroundImage
                backgroundImageDesktop={termsBanner}
                backgroundImageTablet={termsBanner768}
                backgroundImageMobile={termsBanner320}
                imageStyle={styles.backgroundImage}
                Svg={Svg}
            >
                <div className={styles.backgroundImageChildren}>
                    <span>Start making money with Fearn's Affiliate Program today!</span>
                    <JoinButton/>
                </div>
            </BackgroundImage>
        </SectionLayout>
    );
};

export default AffiliateStartSection;
