import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import TermsTitleSection from "../../sections/TermsAndConditions/TermsTitleSection";
import Footer from "../../components/Footer";
import AffiliateProgramContent from "../../components/AffiliateProgramContent";
import AffiliateStartSection from "../../sections/AffiliateStartSection";

const PageContent = () => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return (
    <>
      <TermsTitleSection title="Affiliate program" />
      <AffiliateProgramContent />
      <AffiliateStartSection />
      <Footer />
    </>
  );
};

const AffiliateProgram = () => {
  return (
    <>
      <Header />
      <PageLayout title="Affiliate program">
        <PageContent />
      </PageLayout>
    </>
  );
};

export default AffiliateProgram;
